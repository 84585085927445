import { HelperMethods } from '@/utils/helper'
export const state = () => ({
  user: null,
  rawProductsData: [],
  products: {},
  allowNoAuthUrlList: ['viz', 'schema/?s', 'schema?s'],
  userLoaded: false,
  embedPublicUser: false,
  isRenewBannerDismissed: false,
  breadcrumbs: [],
  routeMap: {
    'Sample Dashboards': '/sample-dashboards',
    Data: '/data',
    Guides: '/guides',
    'Release Notes': '/release-notes',
    'Admin Apps': '/admin',
  },
  routeSubCategory: {
    'Commercial analytics': 'commercial-analytics',
    'Sales operations': 'sales-ops',
    'MDM/Customer master': 'mdm-customer-master',
  },
  isUserIdSet: false,
  isUnderMaintenance: false,
})

export const mutations = {
  SET_USER(state, payload) {
    state.user = payload
  },
  SET_RAW_PRODUCTS_DATA(state, payload) {
    state.rawProductsData = payload
  },
  SET_PRODUCTS(state, payload) {
    state.products = getProducts(payload)
  },
  SET_USER_LOADED(state, payload) {
    state.userLoaded = payload
  },
  EMBED_PUBLIC_USER(state, payload) {
    state.embedPublicUser = payload
  },
  SET_RENEW_DISMISSED(state, payload) {
    state.isRenewBannerDismissed = payload
  },
  SET_BREADCRUMBS(state, payload) {
    state.breadcrumbs = []
    // Payload can be sent null to reset the breadcrumbs
    if (payload) {
      setParentItem(state, payload)
      state.breadcrumbs.push(payload)
    }
  },
  UPDATE_NOTIFICATION_STATUS_AS_READ(state, notification_ids) {
    // Updates/mark the notification center's notification status to read on user interaction.
    // User interaction can be a notification item click or the card click
    state.user.notifications.forEach((notification) => {
      if (notification_ids.includes(notification.id)) {
        notification.read = true
      }
    })
  },
  UPDATE_PRODUCT_NOTIF_STATUS(state, product) {
    // The banners on the product cards are shown based on the notif_status of a product.
    // The same should be updated to 'state.products' to refresh the card's status
    // This refresh is handled in particular for the updates banner click as
    // there will be no route change and api call to refresh the data.
    if (product.category === 'Sample Dashboards') {
      state.products['SampleDashboards'].forEach((item) => {
        item.data.forEach((item) => {
          if (item.slug === product.slug) {
            item.notif_status = null
          }
        })
      })
    } else {
      state.products[product.category.replace(' ', '')].forEach((item) => {
        if (item.slug === product.slug) {
          item.notif_status = null
        }
      })
    }
  },
  UPDATE_IS_USER_ID_SET(state, payload) {
    state.isUserIdSet = payload
  },
}

function setParentItem(state, currentRoute) {
  const product = state.rawProductsData.find((product) =>
    // Child routes will hold the parent route path --> currentRoute.path
    // Find the parent item using the path in currentRoute
    product.primary_url.includes(currentRoute.path)
  )
  if (!product) return
  const parent = {
    name: product.category,
    path: state.routeMap[product.category],
    isActive: false,
  }
  if (product.sub_category) {
    parent.path += `/${state.routeSubCategory[product.sub_category]}`
  }
  state.breadcrumbs.push(parent)
}

function cleanedData(product) {
  let data = {
    title: product.verbose_name,
    subTitle: product.description,
    svgPath: product.icon,
    url: product.primary_url,
    notif_status: product.notif_status ? product.notif_status : null,
    category: product.category,
    slug: product.slug,
  }
  if (product.secondary_url !== null) {
    data['secondaryUrl'] = product.secondary_url
    data['secondaryUrlText'] = product.secondary_url_text
  }
  if (product.icon === null) {
    data['svgPath'] = '/Group 1040.svg'
  } else if (product.icon.startsWith('/') && !product.isIconStatic) {
    data['svgPath'] = process.env.rootURL + product.icon
  }
  return data
}

function customProductNotifStatus(notifications, product) {
  if (
    notifications &&
    (product.category === 'Release Notes' || product.category === 'Guides')
  ) {
    const notification = notifications.find(
      (item) => item.id === product.slug && !item.read
    )
    if (notification) {
      return { category: 'New', notifications: [notification.id], count: 1 }
    }
  } else {
    return product.notif_status || null
  }
}

function getProducts(rawProductsData) {
  let Products = {
    Data: [],
    SampleDashboards: [],
    Guides: [],
    ReleaseNotes: [],
    Admin: [],
  }

  let sampleDashboardCategories = [
    {
      heading: 'MDM/Customer master',
      slug: `sample-dashboards/mdm-customer-master`,
      data: [],
    },
    {
      heading: 'Commercial analytics',
      slug: `sample-dashboards/commercial-analytics`,
      data: [],
    },
    {
      heading: 'Sales operations',
      slug: `sample-dashboards/sales-ops`,
      data: [],
    },
  ]

  let overrideSchemaProdData = {
    slugs: ['schema_app', 'schema_v3'],
    data: {
      verbose_name: 'Data dictionary',
      description:
        'Tables and fields for all the data in McKesson Compile, including medical and pharmacy claims, provider affiliations and provider metadata',
    },
  }
  let schema_apps = overrideSchemaProdData.slugs
  let bothSchema =
    rawProductsData.filter((product) => schema_apps.includes(product.slug))
      .length == 2

  rawProductsData.forEach((product) => {
    if (product.category === 'Sample Dashboards') {
      sampleDashboardCategories.forEach((s) => {
        if (s.heading === product.sub_category) {
          let data = cleanedData(product)
          s.data.push(data)
        }
      })
    } else {
      if (!bothSchema && schema_apps.includes(product.slug)) {
        product = {
          ...product,
          verbose_name: overrideSchemaProdData.data.verbose_name,
          description: overrideSchemaProdData.data.description,
        }
      }
      let data = cleanedData(product)
      // Removing the space in Products key name. This is to handle the category name with space(Release Notes).
      // It is not a good practice to have the space in object keys.
      if (product.category.replace(' ', '') in Products) {
        Products[product.category.replace(' ', '')].push(data)
      }
    }
  })

  sampleDashboardCategories.forEach((c, i) => {
    if (c.data.length > 0) {
      Products.SampleDashboards.push(c)
    }
  })

  return Products
}

function getReleaseNotesProduct() {
  return [
    {
      slug: 'oct-2024',
      verbose_name: 'McKesson Compile™ Product Updates - October, 2024',
      description:
        'This month’s product update highlights the work our team is doing to improve the quality and usability of McKesson Compile data so that you and your team can get the most out of our partnership. ',
      primary_url: process.env.clientDomain + '/release-notes/oct-2024',
      secondary_url: null,
      secondary_url_text: null,
      category: 'Release Notes',
      sub_category: '',
      icon: '/icons/oct2024.svg',
      isIconStatic: true,
      notif_status: null,
    },
    {
      slug: 'aug-2024',
      verbose_name: 'McKesson Compile™ Product Updates - August, 2024',
      description:
        'This month’s product updates highlight our continued focus on creating highly accurate affiliation and hierarchy data within Provider 360 ReadyData™.',
      primary_url: process.env.clientDomain + '/release-notes/aug-2024',
      secondary_url: null,
      secondary_url_text: null,
      category: 'Release Notes',
      sub_category: '',
      icon: '/icons/aug2024.svg',
      isIconStatic: true,
      notif_status: null,
    },
    {
      slug: 'jul-2024',
      verbose_name: 'McKesson Compile™ Product Updates - July, 2024',
      description:
        'We have a jam-packed monthly update for you with several exciting product and organizational updates.',
      primary_url: process.env.clientDomain + '/release-notes/jul-2024',
      secondary_url: null,
      secondary_url_text: null,
      category: 'Release Notes',
      sub_category: '',
      icon: '/icons/jul2024.svg',
      isIconStatic: true,
      notif_status: null,
    },
    {
      slug: 'jun-2024',
      verbose_name: 'McKesson Compile Product Updates - June, 2024',
      description:
        'This month we’ve added nearly 22,000 child to parent HCO mappings in Provider 360 ReadyData and made several usability and data cleanliness improvements.',
      primary_url: process.env.clientDomain + '/release-notes/jun-2024',
      secondary_url: null,
      secondary_url_text: null,
      category: 'Release Notes',
      sub_category: '',
      icon: '/icons/jun2024.svg',
      isIconStatic: true,
      notif_status: null,
    },
    {
      slug: 'may-2024',
      verbose_name: 'McKesson Compile Product Updates - May, 2024',
      description:
        'This month we have added 30,000 HCO mappings to the hierarchy in Provider 360 ReadyData and made additional modifications to the EHR data to ensure patient confidentiality.',
      primary_url: process.env.clientDomain + '/release-notes/may-2024',
      secondary_url: null,
      secondary_url_text: null,
      category: 'Release Notes',
      sub_category: '',
      icon: '/icons/may2024.svg',
      isIconStatic: true,
      notif_status: null,
    },
    {
      slug: 'mar-2024',
      verbose_name: 'Compile Product Updates - March, 2024',
      description:
        'Social Determinants of Health (SDOH) data is now available in Compile ReadyData.',
      primary_url: process.env.clientDomain + '/release-notes/mar-2024',
      secondary_url: null,
      secondary_url_text: null,
      category: 'Release Notes',
      sub_category: '',
      icon: '/icons/mar2024.svg',
      isIconStatic: true,
      notif_status: null,
    },
    {
      slug: 'jan-2024',
      verbose_name: 'Compile Product Updates - January, 2024',
      description:
        'This month we transitioned the last of our medical claims sources to a weekly refresh cadence, so all medical claims are now updated on a weekly cadence.',
      primary_url: process.env.clientDomain + '/release-notes/jan-2024',
      secondary_url: null,
      secondary_url_text: null,
      category: 'Release Notes',
      sub_category: '',
      icon: '/icons/jan2024.svg',
      isIconStatic: true,
      notif_status: null,
    },
    {
      slug: 'dec-2023',
      verbose_name: 'Compile Product Updates - December, 2023',
      description:
        'This month we fixed a bug that had zeroed out a small number of diagnosis code values.',
      primary_url: process.env.clientDomain + '/release-notes/dec-2023',
      secondary_url: null,
      secondary_url_text: null,
      category: 'Release Notes',
      sub_category: '',
      icon: '/icons/dec2023.svg',
      isIconStatic: true,
      notif_status: null,
    },
    {
      slug: 'nov-2023',
      verbose_name: 'Compile Product Updates - November, 2023',
      description:
        'This month we updated the meta information for ~400 health systems, which constitutes ~6% of health systems with affiliations in Compile data.',
      primary_url: process.env.clientDomain + '/release-notes/nov-2023',
      secondary_url: null,
      secondary_url_text: null,
      category: 'Release Notes',
      sub_category: '',
      icon: '/icons/nov2023.svg',
      isIconStatic: true,
      notif_status: null,
    },
    {
      slug: 'oct-2023',
      verbose_name: 'Compile Product Updates - October, 2023',
      description:
        'This month we updated the health care organization NPI for ~2,000 facilities, which is less than 1% of the facilities in our dataset.',
      primary_url: process.env.clientDomain + '/release-notes/oct-2023',
      secondary_url: null,
      secondary_url_text: null,
      category: 'Release Notes',
      sub_category: '',
      icon: '/icons/oct2023.svg',
      isIconStatic: true,
      notif_status: null,
    },
    {
      slug: 'sep-2023',
      verbose_name: 'Compile Product Updates - September, 2023',
      description:
        'This month we enhanced the addresses in our affiliations database by standardizing the addresses to the postal service guidelines, merging duplicate addresses and enforcing consistent address IDs. Overall, 14% of addresses have been updated.',
      primary_url: process.env.clientDomain + '/release-notes/sep-2023',
      secondary_url: null,
      secondary_url_text: null,
      category: 'Release Notes',
      sub_category: '',
      icon: '/icons/sep2023_1.svg',
      isIconStatic: true,
      notif_status: null,
    },
    {
      slug: 'aug-2023',
      verbose_name: 'Compile Product Updates - August, 2023',
      description:
        'This month we increased the accuracy of HCP to facility affiliations by 5%, by removing some inaccurate primary HCP to facility affiliations. These affiliations were mostly administrative addresses and are replaced with an affiliation to a site of care.',
      primary_url: process.env.clientDomain + '/release-notes/aug-2023',
      secondary_url: null,
      secondary_url_text: null,
      category: 'Release Notes',
      sub_category: '',
      icon: '/icons/aug2023.svg',
      isIconStatic: true,
      notif_status: null,
    },
    {
      slug: 'jul-2023',
      verbose_name: 'Compile Product Updates - July, 2023',
      description:
        'This month we improved matching of Compile IDs to ClinicalTrials.gov.',
      primary_url: process.env.clientDomain + '/release-notes/jul-2023',
      secondary_url: null,
      secondary_url_text: null,
      category: 'Release Notes',
      sub_category: '',
      icon: '/icons/jul2023.svg',
      isIconStatic: true,
      notif_status: null,
    },
    {
      slug: 'jun-2023',
      verbose_name: 'Compile Product Updates - June, 2023',
      description:
        'This month’s updates include new features in Compile Console and corrections to a few minor data issues.',
      primary_url: process.env.clientDomain + '/release-notes/jun-2023',
      secondary_url: null,
      secondary_url_text: null,
      category: 'Release Notes',
      sub_category: '',
      icon: '/icons/jun2023.svg',
      isIconStatic: true,
      notif_status: null,
    },
    {
      slug: 'may-2023',
      verbose_name: 'Compile Product Updates - May, 2023',
      description:
        "This month's updates include Compile patient ID tokenization upgrade, change in Compile's affiliations coverage, increased NDC to HCPCS relationship coverage and updated data dictionary",
      primary_url: process.env.clientDomain + '/release-notes/may-2023',
      secondary_url: null,
      secondary_url_text: null,
      category: 'Release Notes',
      sub_category: '',
      icon: '/icons/may2023.svg',
      isIconStatic: true,
      notif_status: null,
    },
    {
      slug: 'apr-2023',
      verbose_name: 'Compile Product Updates - Apr, 2023',
      description:
        "This month's updates include additional historical claims and notice of an upcoming change to patient IDs",
      primary_url: process.env.clientDomain + '/release-notes/apr-2023',
      secondary_url: null,
      secondary_url_text: null,
      category: 'Release Notes',
      sub_category: '',
      icon: '/icons/apr2023.svg',
      isIconStatic: true,
      notif_status: null,
    },
    {
      slug: 'mar-2023',
      verbose_name: 'Compile Product Updates - Mar, 2023',
      description:
        'This month’s updates include new columns for claims tables, improved PubMed matching of HCPs, an upgraded experience for Compile Console',
      primary_url: process.env.clientDomain + '/release-notes/mar-2023',
      secondary_url: null,
      secondary_url_text: null,
      category: 'Release Notes',
      sub_category: '',
      icon: '/icons/mar2023.svg',
      isIconStatic: true,
      notif_status: null,
    },
    {
      slug: 'feb-2023',
      verbose_name: 'Compile Product Updates - Feb, 2023',
      description:
        'This month’s updates include expedited claims delivery and upgraded payer channel classifications.',
      primary_url: process.env.clientDomain + '/release-notes/feb-2023',
      secondary_url: null,
      secondary_url_text: null,
      category: 'Release Notes',
      sub_category: '',
      icon: '/icons/feb2023.svg',
      isIconStatic: true,
      notif_status: null,
    },
  ]
}
function getFAQProduct() {
  return {
    slug: 'faq',
    verbose_name: 'FAQ',
    description:
      'Answers to all the questions pertaining to McKesson Compile services and medical data.',
    primary_url: process.env.clientDomain + '/guides/faq',
    secondary_url: null,
    secondary_url_text: null,
    category: 'Guides',
    sub_category: '',
    icon: '/icons/faq.svg',
    isIconStatic: true,
  }
}

export const actions = {
  GET_USER_PROFILE({ commit, state }, access = 'compile-staff') {
    const self = this
    return new Promise((resolve) => {
      const url = '/api/v1/users/me/'
      self.$axios
        .get(url, {
          withCredentials: true,
        })
        .then((response) => {
          if (
            response &&
            response.is_active &&
            ((access === 'compile-staff' &&
              response.is_staff &&
              response.is_compile_user) ||
              response.entitled_products.find((product) => {
                return product.slug === access
              }) ||
              access === 'all')
          ) {
            let name = response.name
            name = name !== null && name.length > 0 ? name : response.username
            // Adding release notes products and the notification status
            // for the same based on the notifications received in api.
            // For custom product notifications, ID of the notification and
            // Slug of the product should be same. FE uses it.
            const releaseNotes = getReleaseNotesProduct()
            releaseNotes.forEach((productItem) => {
              productItem.notif_status = customProductNotifStatus(
                response.notifications,
                productItem
              )
            })
            response.entitled_products.push(...releaseNotes)
            //Adding faq(guides --> faq) product
            const faq = getFAQProduct()
            faq.notif_status = customProductNotifStatus(
              response.notifications,
              faq
            )
            response.entitled_products.push(faq)
            const userObject = Object.assign({}, response, { name })
            commit('SET_RAW_PRODUCTS_DATA', userObject.entitled_products)
            commit('SET_PRODUCTS', userObject.entitled_products)

            delete userObject.entitled_products
            commit('SET_USER', userObject)

            // sentry set user context
            this.$sentry.setUser({ email: userObject.email })
            this.$sentry.setContext('userinfo', {
              hashid: userObject.hashid,
            })

            resolve(response)
          } else {
            // Allow viz and schema share url to be accessed by user without authentication.
            const noAuthAllowed = state.allowNoAuthUrlList.some((word) =>
              window.location.href.includes(word)
            )
            if (noAuthAllowed) {
              return
            }
            let errorMessage =
              'You do not have access to this, please contact compile_support@mckesson.com'
            if (access === 'compile-staff') {
              errorMessage =
                'You do not have access to this. Please note that this is an alpha version of the tool, and the access to it is limited.'
            }
            commit(
              'SET_APPERROR',
              {
                status: true,
                message: errorMessage,
                summary: 'Unauthorised',
                level: 'warning',
                showCTASection: true,
              },
              { root: true }
            )
          }
        })
        .finally(() => {
          // Initializing Google Analytics:
          // The user_id is set to hashid (unique identifier) if the user is logged in, otherwise it is set to "public".
          // If the user is not logged in, making an API request to "/users/me/" will result in an error.
          // However, certain pages like the viz-dashboard or schema with share key, which can be accessed without authentication, still require analytics tracking.
          // Therefore, we need to initialize the Google Analytics tracking regardless of the user's authentication status.
          const userId =
            state.user && state.user.hashid ? state.user.hashid : 'public'
          if (!state.isUserIdSet) {
            let shareKey = ''
            if (window.location.search !== '') {
              const queryStrings = new URLSearchParams(window.location.search)
              shareKey = queryStrings.get('s')
            }
            HelperMethods.initializeGtag(userId, shareKey)
            commit('UPDATE_IS_USER_ID_SET', true)
          }
        })
    })
  },
  UPDATE_NOTIFICATION_STATUS({ commit }, notification_ids) {
    const self = this
    return new Promise((resolve) => {
      const url = '/api/v1/users/notification/mark-read/'
      self.$axios
        .post(url, {
          notifications: notification_ids,
        })
        .then((response) => {
          commit('UPDATE_NOTIFICATION_STATUS_AS_READ', notification_ids)
          resolve(response)
        })
    })
  },
}
